<template>
	<BaseLoading v-if="isRoleLoading" is-full-page />
	<CForm
		v-else
		class="main-wrapper"
		@submit.prevent="submit"
	>
		<CRow align-horizontal="center">
			<CCol lg="12">
				<CInput
					v-model.trim="$v.username.$model"
					:label="`${$t('page.users.create.formLabel.username')}*`"
					:is-valid="isValidUsername"
					:invalid-feedback="getValidateUsernameMessage"
					:description="getUsernameDescription"
					@input="clearError"
				/>
			</CCol>
		</CRow>
		<CRow align-horizontal="center">
			<CCol lg="12">
				<CInput
					v-model.trim="$v.password.$model"
					:label="`${$t('page.users.create.formLabel.password')}*`"
					:is-valid="isValidPassword"
					:invalid-feedback="getValidatePasswordMessage"
					:description="getPasswordDescription"
					@input="clearError"
				/>
			</CCol>
		</CRow>
		<CRow align-horizontal="center">
			<CCol lg="6">
				<CInput
					v-model.trim="$v.firstName.$model"
					:label="`${$t('page.users.create.formLabel.firstName')}*`"
					:is-valid="isValidFirstName"
					:invalid-feedback="getValidateFirstNameMessage"
					@input="clearError"
				/>
			</CCol>
			<CCol lg="6">
				<CInput
					v-model.trim="$v.lastName.$model"
					:label="$t('page.users.create.formLabel.lastName')"
					:is-valid="isValidLastName"
					:invalid-feedback="getValidateLastNameMessage"
					@input="clearError"
				/>
			</CCol>
		</CRow>
		<CRow align-horizontal="center">
			<CCol lg="12">
				<BaseDropDown
					v-model="$v.group.$model"
					:options="userGroupDropdown"
					:searchable="false"
					:is-valid="!$v.group.$error"
					:invalid-feedback="$t('global.error.required')"
					label-drop-down="User group"
					label="name"
					track-by="value"
					placeholder="Please select group"
				/>
			</CCol>
		</CRow>
		<CRow align-horizontal="center">
			<CCol
				class="wrapper-active-field mt-4 mb-4"
				lg="12"
			>
				<label class="switch-active-label">
					{{ $t('page.users.create.formLabel.status') }}
				</label>
				<CSwitch
					class="switch-custom swtich-active-status-button"
					name="status"
					variant="3d"
					size="sm"
					color="success"
					@update:checked="handleClickSwitch"
				/>
				<p class="label-active-status typo-body-2">
					{{ activeLabel }}
				</p>
			</CCol>
		</CRow>
		<CRow align-horizontal="center">
			<CCol lg="12">
				<CInput
					v-model.trim="$v.email.$model"
					:label="$t('page.users.create.formLabel.email')"
					:is-valid="isValidEmail"
					:invalid-feedback="getValidateEmailMessage"
					@input="clearError"
				/>
			</CCol>
		</CRow>
		<CRow align-horizontal="center">
			<CCol lg="12">
				<CInput
					v-model.trim="$v.phone.$model"
					:label="$t('page.users.create.formLabel.phone')"
					:is-valid="isValidPhone"
					:invalid-feedback="getValidatePhoneMessage"
					@input="clearError"
				/>
			</CCol>
		</CRow>
		<CRow align-horizontal="center">
			<CCol lg="12">
				<BaseDropDown
					v-model="store"
					:options="warehouseOptions"
					label="name"
					label-drop-down="Store ID"
					placeholder="Select store"
				/>
			</CCol>
		</CRow>
		<CRow align-horizontal="center" class="mb-5">
			<CCol lg="12">
				<CTextarea
					v-model.trim="$v.note.$model"
					:label="$t('page.users.create.formLabel.note')"
					:is-valid="isValidNote"
					:invalid-feedback="getValidateNoteMessage"
					@input="clearError"
				/>
			</CCol>
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isLoading"
			content-class="main-wrapper"
			@onConfirm="submit"
			@onCancel="$router.push({ name: 'Users'})"
		/>
	</CForm>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength, email, maxLength, helpers } from 'vuelidate/lib/validators';
import { validateHandler, checkIfValid } from '../assets/js/selectors/global/validation';
import { scrollToTop } from '../assets/js/helpers';
import username from '../regex/username';

export default {
	validations: {
		username: {
			required,
			minLength: minLength(4), // false
			maxLength: maxLength(255),
			alphanumeric: helpers.regex('alphanumeric', username),
		},
		password: {
			required,
			minLength: minLength(8),
			maxLength: maxLength(255),
		},
		firstName: {
			required,
			maxLength: maxLength(35),
		},
		lastName: {
			maxLength: maxLength(35),
		},
		email: {
			format(value) {
				return email(value);
			},
			maxLength: maxLength(320),
		},
		phone: {
			maxLength: maxLength(255),
		},
		note: {
			maxLength: maxLength(150),
		},
		group: {
			required,
		},
	},

	mixins: [validationMixin],

	data() {
		return {
			activeLabel: this.$t('page.users.create.value.status.inactive'),
			username: null,
			password: null,
			firstName: null,
			lastName: '',
			email: null,
			phone: null,
			store: null,
			note: null,
			group: null,
			statusActive: false,
			isRoleLoading: false,
		};
	},
	computed: {
		...mapState('users', {
			create: 'create',
		}),
		...mapState('roles', {
			roleList: 'list',
		}),
		...mapGetters({
			warehouseOptions: 'warehouses/warehouseOptions',
		}),
		isLoading() {
			return this.create.isLoading;
		},
		isSuccess() {
			return this.create.isSuccess;
		},
		fieldErrors() {
			return this.create.fieldErrors;
		},
		isValidUsername() {
			const field = this.$v.username;
			return checkIfValid(field);
		},
		isValidPassword() {
			const field = this.$v.password;
			return checkIfValid(field);
		},
		isValidEmail() {
			const field = this.$v.email;
			return checkIfValid(field);
		},
		isValidPhone() {
			const field = this.$v.phone;
			return checkIfValid(field);
		},
		isValidFirstName() {
			const field = this.$v.firstName;
			return checkIfValid(field);
		},
		isValidLastName() {
			const field = this.$v.lastName;
			return checkIfValid(field);
		},
		isValidNote() {
			const field = this.$v.note;
			return checkIfValid(field);
		},
		getValidateUsernameMessage() {
			const field = this.$v.username;

			return validateHandler(field, {
				existMessage: this.$t('page.users.create.validationMessage.username.exists'),
				requiredMessage: this.$t('page.users.create.validationMessage.username.required'),
				lengthLimitMessage: this.$t('page.users.create.validationMessage.username.lengthLimit'),
				alphaNumnericMessage: this.$t('page.users.create.validationMessage.username.alphanumberic'),
			});
		},
		getValidatePasswordMessage() {
			const field = this.$v.password;

			return validateHandler(field, {
				requiredMessage: this.$t('page.users.create.validationMessage.password.required'),
				lengthLimitMessage: this.$t('page.users.create.validationMessage.password.lengthLimit'),
			});
		},
		getValidateEmailMessage() {
			const field = this.$v.email;
			return validateHandler(field, {
				existMessage: this.$t('page.users.create.validationMessage.email.exists'),
				lengthLimitMessage: this.$t('page.users.create.validationMessage.email.lengthLimit'),
				invalidFormatMessage: this.$t('page.users.create.validationMessage.email.invalidFormat'),
			});
		},
		getValidatePhoneMessage() {
			const field = this.$v.email;
			return validateHandler(field, {
				lengthLimitMessage: this.$t('page.users.create.validationMessage.phone.lengthLimit'),
			});
		},
		getValidateFirstNameMessage() {
			const field = this.$v.firstName;

			return validateHandler(field, {
				requiredMessage: this.$t('page.users.create.validationMessage.firstName.required'),
				lengthLimitMessage: this.$t('page.users.create.validationMessage.firstName.lengthLimit'),
			});
		},
		getValidateLastNameMessage() {
			const field = this.$v.lastName;
			return validateHandler(field, {
				lengthLimitMessage: this.$t('page.users.create.validationMessage.lastName.lengthLimit'),
			});
		},
		getValidateNoteMessage() {
			const field = this.$v.note;
			return validateHandler(field, {
				lengthLimitMessage: this.$t('page.users.create.validationMessage.note.lengthLimit'),
			});
		},
		getUsernameDescription() {
			const field = this.$v.username;
			if (field.$error) {
				return '';
			}
			return this.$t('page.users.create.description.username');
		},
		getPasswordDescription() {
			const field = this.$v.password;

			if (field.$error) {
				return '';
			}
			return this.$t('page.users.create.description.password');
		},
		formValid() {
			return !this.$v.$invalid;
		},
		userGroupDropdown() {
			const roles = this.roleList.data;

			return roles.map((role) => ({
				name: role.name, value: role.id,
			}));
		},
	},
	async created() {
		this.isRoleLoading = true;

		try {
			await this.getRoleList();
			await this.getWarehouseList({ per_page: 'all' });
		} finally {
			this.isRoleLoading = false;
		}
	},
	methods: {
		...mapActions({
			getRoleList: 'roles/getRoleList',
			showToast: 'toast/showToast',
			postUser: 'users/postUser',
			clearError: 'users/clearError',
			getWarehouseList: 'warehouses/getWarehouseList',
		}),
		async submit() {
			this.$v.$touch();
			if (this.formValid) {
				await this.postUser({
					username: this.username,
					email: this.email,
					password: this.password,
					first_name: this.firstName,
					last_name: this.lastName,
					is_active: this.statusActive,
					phone_number: this.phone,
					store_id: this.store ? this.store.value : null,
					role_id: this.group.value,
					note: this.note,
				});

				// Redirect to user list page after created
				if (this.isSuccess) {
					this.$router.push({ name: 'UserLists' });

				// Show toast if api return any errors
				} else {
					let errorMessage = this.$t('global.errorMessage');
					if (this.fieldErrors.username) {
						errorMessage = this.fieldErrors.username;
					} else if (this.fieldErrors.email) {
						errorMessage = this.fieldErrors.email;
					}

					this.showToast({
						content: errorMessage,
						header: this.$t('global.errorMessageTitleCreate'),
						type: 'danger',
					});

					scrollToTop();
				}
			} else {
				// Scroll to top if form is invalid
				scrollToTop();
			}
		},
		handleSelectGroup(value) {
			this.clearError();
			this.group = value;
		},
		handleClickSwitch(checked) {
			this.clearError();
			if (checked) {
				this.activeLabel = this.$t('page.users.create.value.status.active');
				this.statusActive = true;
			} else {
				this.activeLabel = this.$t('page.users.create.value.status.inactive');
				this.statusActive = false;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
	.btn-cancel,
	.btn-create {
		min-width: rem(132);
	}

	.btn-cancel {
		margin-left: rem(34);
	}

	.wrapper-active-field {
		display: flex;
		align-items: center;

		.switch-active-label {
			margin-right: rem(40);
			margin-bottom: 0;
		}

		.swtich-active-status-button {
			margin-right: rem(12);
		}

		.label-active-status {
			margin-bottom: 0;
		}
	}
</style>
